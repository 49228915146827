import React from 'react'
import { Button, Form, Icon, Message } from 'semantic-ui-react';
import { Link } from 'gatsby';
import { Loader } from '@googlemaps/js-api-loader';

// const addEventListener = typeof window === 'undefined' ? () => ({}) : window.addEventListener;
// const removeEventListener = typeof window === 'undefined' ? () => ({}) : window.removeEventListener;

// for reference: https://github.com/dwyl/html-form-send-email-via-google-script-without-server

function polyfilledFetch(url, body) {
	if (typeof fetch === 'undefined') {
		return polyfillFetch(url, JSON.stringify(body));
	} else {
		return fetch(url, {
			method: 'post',
			headers: { 'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8' },
			body,
		}).then(response => response.json());
	}
}

function polyfillFetch(url, body) {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open('POST', url, true);
		xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=UTF-8');
		xhr.onreadystatechange = () => {
			if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
				resolve(JSON.parse(xhr.response));
			}
		};
		xhr.error = reject;
		xhr.send(body);
	});
}

export default class ContactForm extends React.Component {
	static defaultProps = { load: true }
	constructor(props) {
		super();
		this.loader = new Loader({
			apiKey: props.mapKey,
			version: "weekly",
			libraries: ["places"]
		});
		if (typeof window !== 'undefined' && !window.google && props.load) {
			this.loader.load();
		}
	}
	state = { busy: false, error: false, reason: null,
		formData: { preferCall: true }, locationOptions: [
			{ key: 'default', text: 'type to search here', value: 'default' }
		],
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const formData = this.state.formData;
    const formKey = this.props.formKey;
		// console.log(formData);
		try {
			if (this.state.success) { throw new Error('Form is already submitted'); }
			const { phone, enquiry, email, name } = formData;
			if (!enquiry) { throw new Error('Enquiry is required'); }
			if (!name) { throw new Error('Name is required'); }
			if (!phone && !email) { throw new Error('At least 1 contact method (email or phone number) is required'); }
		} catch (error) {
			// show error message
			return this.setState({ error: true, reason: error.message || error, success: undefined });
			// console.log(error)
		}
		this.setState({ busy: true });
		polyfilledFetch(`https://script.google.com/macros/s/${formKey}/exec`, JSON.stringify(formData)).then(data => {
			if (data.result !== 'success') { throw data.error || data; }
			this.setState({ success: true });
			// console.log(success);
		}).catch(error => {
			this.setState({ error: true, reason: error.message || error, success: undefined });
		}).then(() => {
			this.setState({ busy: false });
		});
	}

	handleFieldChange = (event, element) => {
		// console.log(element, this.state);
		this.setState({
			success: undefined,
			error: false,
			formData: {
				...this.state.formData,
				[element.name]: element.type === 'checkbox' ? element.checked : element.value,
			}
		});
	}

	handleChange = () => {
		this.setState({ error: false, reason: null });
	}

	handleInitialLocationChange = () => {
		// on first change of location input
		// loads google map, shows loading
		// after loading, get location
		// pan & set marker
		// const [locationInput] = document.getElementsByName('location');
		// const searchBox = new window.google.maps.places.SearchBox(locationInput);
		// searchBox.addListener('places_changed', () => {
		// 	const [place] = searchBox.getPlaces();
		// 	console.log(place)
		// 	!!place && this.map.panTo(place.geometry.location);
		// });
		// this._searchBox = searchBox;
		// window.test = searchBox;
	}

	handleSearchChange = (event, { searchQuery }) => {
		// console.log(value);
		if (!searchQuery) { return; }
		if (typeof window === 'undefined') { return; }
		if (window.google && window.google.maps.places) {
			const service = new window.google.maps.places.AutocompleteService();
			service.getQueryPredictions({ input: searchQuery }, (predictions, status) => {
				if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
					console.error('unable find predictions', status);
				} else {
					const options = predictions.filter(o => {
						return o.structured_formatting.secondary_text === 'Singapore';
					}).map(o => {
						return { key: o.structured_formatting.main_text, text: o.description, value: o.description };
					});
					this.setState({
						locationOptions: options,
					});
				}
			});
		}
	}

	render() {
		const isValid = true;
		// let script = null;
		// if (typeof window !== 'undefined' && window.google) {
		// 	const present = Array.from(document.getElementsByTagName('script')).find(o => o.src.startsWith('https://maps.googleapis'));
		// 	if (present) {
		// 		script = <script async src={`https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=places`}></script>;
		// 	}
		// }
		return (
			<div>
				<h2>Contact us</h2>
				<p>The information provided in this form is used in accordance to our <Link to="/privacy">privacy policy</Link>.</p>
				<Form success={!!this.state.success} error={this.state.error} onChange={this.handleChange}>
					<Form.Input required name='name' label='Name' icon='user' iconPosition='left' placeholder="Your name or company name" onChange={this.handleFieldChange} />
					<Form.Input required name='email' label='Email' icon='mail' iconPosition='left' placeholder="Your email" onChange={this.handleFieldChange} />
					<Form.Input required name='phone' label='Phone' icon='call' iconPosition='left' placeholder="Your phone number" onChange={this.handleFieldChange} />
					<Form.TextArea required name='enquiry' label='Enquiry' placeholder="How can we help you?" onChange={this.handleFieldChange} />
					<Form.Group inline id="formgroup">
						<label htmlFor="formgroup">Preferred contact</label>
						<Form.Checkbox name='preferCall' label='Call' defaultChecked onChange={this.handleFieldChange} />
						<Form.Checkbox name='preferEmail' label='Email' onChange={this.handleFieldChange} />
						<Form.Checkbox name='preferWhatsapp' label='Whatsapp' onChange={this.handleFieldChange} />
					</Form.Group>
					{/*Should have map picker here*/}
					{/*<Form.Input name='location' label='Location' icon='location arrow' iconPosition='left' placeholder="Where is the premise?" onChange={this.handleFieldChange} />*/}
					<Form.Select name='location' label='Location' defaultOpen={true} search={true} allowAdditions={false} options={this.state.locationOptions} placeholder="Where is the premise?" onSearchChange={this.handleSearchChange} onChange={this.handleFieldChange}/>
					<Message error header='More information required' content={this.state.reason} />
					<Message success header='Form Submitted' content="We will contact you shortly" />
					<Button type='submit' loading={this.state.busy} disabled={!isValid} onClick={this.handleSubmit}>
						<Icon name='send' />
						Send
					</Button>
				</Form>
			</div>
		);
	}
}