import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Menu, Icon, Image, Modal } from 'semantic-ui-react';
import logoinverted from '../assets/images/logos/logoinverted.svg';
import logo from '../assets/images/logos/logo.svg';
import ContactForm from './contactform';
import useViewPort from '../utils/useViewPort';


const options = [
  { link: '/', name: 'logo', icon: 'home', text: 'Home' },
  { link: '/about/', name: 'menu', icon: 'info', text: 'About' },
  { link: '/services/', name: 'menu', icon: 'wrench', text: 'Services' },
  { link: '/greenpolicy/', name: 'menu', icon: 'envira', text: 'Green Policy' },
  // { link: '/news/', name: 'menu', icon: 'newspaper', text: 'News' },
  // { link: '/portfolio/', name: 'menu', icon: 'favorite', text: 'Portfolio' },
  { link: '/services#featured', name: 'menu', icon: 'favorite', text: 'Featured' },  // anchor can be /services/#featured but must not have trailing /
  { link: '/contact/', name: 'menu', icon: 'phone', text: 'Contact' },
];

function getActiveIndex(params) {
  if (!params) { return 0; }
  const {hash, pathname} = params;
  const path = `${pathname}${hash}`.split(/\/|#/);
  return options.reduce((memo, { link }, idx) => {
    const arr = link.split(/\/|#/);
    const weight = path.reduce((mat, piece) => {
      return mat + !!arr.includes(piece);
    }, 0);
    if (weight > memo.weight) { return { index: idx, weight }; }
    return memo;
  }, { index: -1, weight: 0 }).index;
}

//  backgroundColor: `rgb(17, 85, 170)`, 
const headerStyle = {position: 'fixed', top: 0, zIndex: 1, width: '100%', height: 35 };
const AppBarWithKey = (rest, ...p) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          domain
          keys {
            formKey
            mapKey
          }
        }
      }
    }
  `);
  const { isMobile } = useViewPort();
  return (
    <AppBar isMobile={isMobile} keys={data.site.siteMetadata.keys} domain={data.site.siteMetadata.domain} {...rest}></AppBar>
  );
}

class AppBar extends React.Component {
  constructor() {
    super();
    this.sidebar = React.createRef();
    this.opener = React.createRef();
  }
  state = { dimmed: false, visible: false }
  toggleContact = () => {
		this.setState({ dimmed: !this.state.dimmed });
  }
  toggleVisibility = () => {
		this.setState({ visible: !this.state.visible });
	}
  onClickOutside = event => {
		const element = this.sidebar.current;
		const opener = this.opener.current;
		// element does not contain target = outside
		if (!opener || !element) { return; }
		if (opener.contains(event.target)) { return; }
		if (!element.contains(event.target)) {
			window.removeEventListener('click', this.onClickOutside, true);
			setTimeout(this.props.toggleVisibility, 50);
		}
	}

  componentDidMount() {
    window.addEventListener('click', this.onClickOutside, true);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClickOutside, true);
  }

  render() {
    const { keys, domain, isMobile, path } = this.props;
    // console.log(this.props)
    const activeIndex = getActiveIndex(this.props.location);
    const sideBarWidth = 210;
    const {visible} = this.state;
    if (isMobile) {
      return (
        <header className="nav" style={{ ...headerStyle, backgroundColor: 'white' }}>
          <Menu attached='top' size='tiny' borderless pointing>
            <Menu.Item name='menu' ref={this.opener} onClick={this.toggleVisibility}><Icon name='content' /></Menu.Item>
            <Menu.Item as={Link} to={`/`} name='Jinmac Pte Ltd' style={{ padding: 3 }}>
              <Image height={`${35 - 6}px`} src={logo}/>
            </Menu.Item>
            <Menu.Item as={Link} to={`/contact/`} name='Contact us' position="right" style={{ marginRight: 5 }}>
            </Menu.Item>
          </Menu>
          <nav className="sidebar" sidebar={this.sidebar} style={{ height: '100vh', width: sideBarWidth, backgroundColor: 'white', position: 'fixed', top: `${35}px`, left: visible ? 0 : `-${sideBarWidth }px`, transition: `left 0.5s ease` }}>
            <Menu borderless pointing secondary vertical>
              {options.map((o, idx) => (
                <Menu.Item key={o.link} active={activeIndex === idx} as={Link} to={o.link} name={o.name}><Icon name={o.icon} />{o.text}</Menu.Item>
              ))}
              <Menu.Item name='whatsapp'><a href={`whatsapp://send?text=https://${domain}${path}`} data-action="share/whatsapp/share">Share via whatsapp</a></Menu.Item>
              <Menu.Item onClick={this.toggleVisibility}><Icon name='angle double left' size="large"/></Menu.Item>
            </Menu>
          </nav>
        </header>
      );
    }

    return (
      <header className="nav" style={{ ...headerStyle, backgroundColor: `rgb(45, 104, 196)` }}>
        <Menu attached="top" size="tiny" secondary text style={{border:0}} inverted>
          <Menu.Item as={Link} to={'/'} name="Jinmac Pte Ltd" style={{padding:3}}>
            <Image height={`${35-6}px`} src={logoinverted}/>
          </Menu.Item>
          <Modal trigger={<Menu.Item name="quickcontact"><Icon name="call"/>Contact us now!</Menu.Item>}>
            <Modal.Content>
              <ContactForm {...keys}/>
            </Modal.Content>
          </Modal>
          <Menu.Menu position="right">
            {options.map((o, idx)=>(
              <Menu.Item key={o.link} as={Link} to={o.link} name={o.name} active={idx === activeIndex}><Icon name={o.icon}/>{o.text} </Menu.Item>
            ))}
          </Menu.Menu>
        </Menu>
      </header>
    );
  }
}


AppBar.propTypes = {
  siteTitle: PropTypes.string,
}

AppBar.defaultProps = {
  siteTitle: ``,
}

export default AppBarWithKey;
