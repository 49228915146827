import React from 'react';
const MOBILE_WIDTH = 768;

const useViewport = () => {
  const [ isMobile, setIsMobile ] = React.useState();
  React.useEffect(() => {
    const mql = window.matchMedia(`(max-width: ${MOBILE_WIDTH}px)`);
    const updator = ({ matches }) => { setIsMobile(matches); };
    mql.addEventListener('change', updator);
    updator(mql);  // required, will cause the blink to transit to mobile though
    return () => mql.removeEventListener('change', updator);
  }, []);  // empty array, run once only
  return { isMobile };
}

// const useViewport = typeof window === 'undefined' ? () => {
//   const [width, setWidth] = React.useState(window.innerWidth);
//   // Add a second state variable "height" and default it to the current window height
//   const [height, setHeight] = React.useState(window.innerHeight);

//   React.useEffect(() => {
//     const handleWindowResize = () => {
//       setWidth(window.innerWidth);
//       // Set the height in state as well as the width
//       setHeight(window.innerHeight);
//     }

//     window.addEventListener("resize", handleWindowResize);
//     return () => window.removeEventListener("resize", handleWindowResize);
//   }, []);

//   // Return both the height and width
//   return { width, height, isMobile: width <= MOBILE_WIDTH };
// } : useViewportMediaQuery;

export default useViewport;