import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { Icon, Divider, Grid } from 'semantic-ui-react';
import useViewPort from '../utils/useViewPort';

import URL from "./url"

import ggbs from '../assets/images/logos/ggbs.svg';
import ggbscert from '../assets/images/certs/ggbscert.svg';
import bizSafe from '../assets/images/logos/bizSafe.svg';
import bizSafecert from '../assets/images/certs/bizSafeStarCert2024.svg';
import ura from '../assets/images/logos/URA-award-all.svg';
import uraaward from '../assets/images/certs/AHAplaque.svg';
import nsmark from '../assets/images/logos/NSmark.svg';
import nsmarkcert from '../assets/images/certs/NSmarkCert.svg';
import quality from '../assets/images/logos/ISO9001Logo.svg';
import qualitycert from '../assets/images/certs/ISO9001Cert.svg';
import ohsas from '../assets/images/logos/ISO45001-logo.svg';
import ohsascert from '../assets/images/certs/ISO45001Cert.svg';
import ems from '../assets/images/logos/ISO14001-logo.svg';
import emscert from '../assets/images/certs/ISO14001Cert.svg';
import sea from '../assets/images/logos/SEAlogo.svg';
import seaaward from '../assets/images/certs/SEAcert.jpg';
import stqb from '../assets/images/logos/stqblogo.svg';
import stqbaward from '../assets/images/certs/STQBcert.jpg';
import LightBox from './lightbox'

const footerStyle = { textAlign: 'center', backgroundColor: 'white', paddingTop: '2rem', flex: '0 0 auto', position: 'relative' };



const AppFooter = (props) => {
  const {
    addressLine1, addressLine2, postalCode, phoneNumber,
    faxNumber, email, uen, domain, webEmail, company
  } = props;
  const { isMobile } = useViewPort();
  return (
    <footer style={footerStyle}>
      <Divider />
      <Grid className="footer grid" columns={isMobile ? 1 : 'equal'} style={{textAlign: 'center', marginBottom: '2rem'}} divided={false}>
        <Grid.Column>
          <p><Icon name="point"/>Location</p>
          <address style={{ display: 'inline-block', textAlign: 'initial', margin: 'auto' }}>
            <p><Icon name="home"/>{addressLine1}</p>
            <p><Icon />{addressLine2}</p>
            <p><Icon />Singapore {postalCode}</p>
            <p><Icon name="phone"/><a href={`tel:${phoneNumber}`}>{phoneNumber}</a></p>
            <p><Icon name="fax" />{faxNumber}</p>
            <p><Icon name="mail" /><a href={`mailto:${email}`}>{email}</a></p>
            <p><Icon name="world" /><a href={`https://${domain}`}>{domain}</a></p>
          </address>
        </Grid.Column>
        <Grid.Column>
          <p><Icon name='legal'/>Legal</p>
          <div>
            <p><Icon name="like"/>{company}</p>
            <p><Icon name="id card"/><a href={`https://www.bca.gov.sg/BCADirectory/Company/Details/${uen}`}>Business Reg. No.: {uen}</a></p>
            <p><Icon name="fork"/><a href={`mailto:${webEmail}?subject=Webmaster Enquiry`}>Webmaster</a></p>
            <p><Icon name="user secret"/><Link to={`/privacy/`}>Privacy Policy</Link></p>
            <p><Icon name="copyright"/>Copyright {new Date().getFullYear()}. All rights reserved.</p>
          </div>
        </Grid.Column>
        <Grid.Column>
						<p style={{ margin: 0 }}><Icon name='address card outline'/> Awards &amp; Certificates</p>
						<div className="logos" style={{ margin: 'auto' }}>
							<div style={{ display: 'inline-flex', flexDirection: 'row', flexWrap: 'wrap' }} >
								<LightBox src={bizSafe} highlight={bizSafecert} caption='bizSafe Star certificate by Workplace Safety and Health (WSH) Council'  imgStyle={{ width: undefined, height: undefined }}/>
                <LightBox src={ggbs} highlight={ggbscert} caption='Green and Gracious certificate by Building and Construction Authority (BCA)'  imgStyle={{ width: undefined, height: undefined }}/>
								<LightBox src={ura} highlight={uraaward} caption={<URL href={'https://www.ura.gov.sg/Corporate/Media-Room/Media-Releases/pr16-62'}>URA Architectural Heritage Award 2016 for restoration</URL>}  imgStyle={{ width: undefined, height: undefined }}/>
								<LightBox src={quality} highlight={qualitycert} caption='ISO 9001:2015 standard for Quality Management System'  imgStyle={{ width: undefined, height: undefined }}/>
								<LightBox src={ohsas} highlight={ohsascert} caption='ISO 45001:2018 standard for health &amp; safety in the workplace'  imgStyle={{ width: undefined, height: undefined }}/>
								<LightBox src={ems} highlight={emscert} caption='ISO 14001:2015 standard for Environmental Management Systems'  imgStyle={{ width: undefined, height: undefined }}/>
								<LightBox src={sea} highlight={seaaward} caption='Singapore Excellence Award 2014'  imgStyle={{ width: undefined, height: undefined }}/>
								<LightBox src={stqb} highlight={stqbaward} caption='Singapore Trusted Quality Brand Award 2015'  imgStyle={{ width: undefined, height: undefined }}/>
								<LightBox src={nsmark} highlight={nsmarkcert} caption='NS Mark' imgStyle={{ width: undefined, height: undefined }}/>
							</div>
						</div>
					</Grid.Column>
      </Grid>
    </footer>
  );
};


// AppFooter.propTypes = {
//   company: PropTypes.string,
//   addressLine1: PropTypes.string,
//   addressLine2: PropTypes.string,
//   postalCode: PropTypes.string,
//   phoneNumber: PropTypes.string,
//   faxNumber: PropTypes.string,
//   email: PropTypes.string,
//   uen: PropTypes.string,
//   domain: PropTypes.string,
//   webEmail: PropTypes.string,
//   coordinates: PropTypes.any,
// }

AppFooter.defaultProps = {
  company: '',
  addressLine1: '',
  addressLine2: '',
  postalCode: '',
  phoneNumber: '',
  faxNumber: '',
  email: '',
  uen: '',
  domain: '',
  webEmail: '',
  coordinates: {},
}

export default AppFooter
