import React from 'react';
import { Modal } from 'semantic-ui-react';
import Img from "gatsby-image"

const LightBox = ({ base64, width, src, srcWebp, sizes, srcSet, srcSetWebp, height, tracedSVG, aspectRatio, alt = '', highlight, caption, floated, showCaption, bordered = false, style, imgStyle, ...rest }) => {
  const fluid = { base64, width, src, srcWebp, sizes, srcSet, srcSetWebp, height, tracedSVG, aspectRatio };
  let mainStyle = {};
  if (floated === 'left') {
    mainStyle = { float: floated, marginRight: '1rem', marginBottom: '1rem' };
  }
  if (floated === 'right') {
    mainStyle = { float: floated, marginLeft: '1rem', marginBottom: '1rem' };
  }
  const expanded = highlight ? { src: highlight } : fluid;
  const overwrite = srcSet ? imgStyle : { ...imgStyle, position: 'relative', maxWidth: '100%' };
  return (
    <Modal
      className="lightbox" size='small'
      trigger={
        <div className={`lightbox${bordered ? ' bordered' : ''}`} style={mainStyle}>
          <Img fluid={fluid} alt={alt} {...rest} style={style} imgStyle={overwrite}/>
          <em>{showCaption && (caption || alt)}</em>
        </div>
      }
      closeIcon='close'
    >
      <Modal.Content style={{ textAlign: 'center' }}>
        <Img wrapped fluid={expanded} alt={alt} className="lightbox highlight" imgStyle={overwrite}/>
        <em>{caption || alt}</em>
      </Modal.Content>
    </Modal>
  );
}

export default LightBox;