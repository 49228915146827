import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import AppBar from "./header"
import AppFooter from "./footer"
import "./layout.css"
import '../assets/semantic/dist/semantic.css';

// if (typeof window !== 'undefined') {
//   // Make scroll behavior of internal links smooth
//   require('smooth-scroll')('a[href*="#"]');
// }

const Layout = ({ children, ...rest }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          company
          addressLine1
          addressLine2
          postalCode
          phoneNumber
          faxNumber
          email
          uen
          domain
          webEmail
        }
      }
    }
  `)
  return (
    <>
      <AppBar {...rest} siteTitle={data.site.siteMetadata?.company || `Title`} />
      <main>{children}</main>
      <AppFooter {...data.site.siteMetadata}/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
